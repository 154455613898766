<template>
    <div>
        <div class="page-wrapper">
            <div class="topbar-one">
                <div class="container">
                    <div class="topbar-one__left">
                        <a href="#">zhyldyzacademy@protonmail.com</a>
                        <a href="#">+996 555 94 95 01</a>
                    </div><!-- /.topbar-one__left -->
                    <div class="topbar-one__right">
                        <a href="#">Login</a>
                        <a href="#">Register</a>
                    </div><!-- /.topbar-one__right -->
                </div><!-- /.container -->
            </div><!-- /.topbar-one -->
            <header class="site-header site-header__header-one site-header__inner-page ">
                <nav class="navbar navbar-expand-lg navbar-light header-navigation stricky">
                    <div class="container clearfix">
                        <!-- Brand and toggle get grouped for better mobile display -->
                        <div class="logo-box clearfix">
                            <a class="navbar-brand" href="index.html">
                                <img :src="require('../../assets/logo_1.png')" class="main-logo" width="128" alt="Awesome Image" />
                            </a>
                            <div class="header__social">
                                <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                                <a href="https://www.facebook.com/zhyldyz.academy" target="_blank"><i class="fab fa-facebook-square"></i></a>
                                <a href="https://api.whatsapp.com/send/?phone=%2B996704949501&text&app_absent=0" target="_blank"><i class="fab fa-whatsapp"></i></a>
                                <a href="https://www.instagram.com/zhyldyz_academy/" target="_blank"><i class="fab fa-instagram"></i></a>
                            </div><!-- /.header__social -->
                            <button class="menu-toggler" data-target=".main-navigation">
                                <span class="kipso-icon-menu"></span>
                            </button>
                        </div><!-- /.logo-box -->
                        <!-- Collect the nav links, forms, and other content for toggling -->
                        <div class="main-navigation">
                            <ul class=" navigation-box">
                                <li>
                                <a @click="goTo('Home')" style="font-weight: 700;">Home</a>
                               <!--<ul class="sub-menu">
                                    <li><a href="index.html">Home 01</a></li>
                                    <li><a href="index-2.html">Home 02</a></li>
                                    <li><a href="index-3.html">Home 03</a></li>
                                    <li><a href="#">Header Versions</a>
                                        <ul class="sub-menu">
                                            <li><a href="index.html">Header 01</a></li>
                                            <li><a href="index-2.html">Header 02</a></li>
                                            <li><a href="index-3.html">Header 03</a></li>
                                        </ul>
                                    </li>
                                </ul> /.sub-menu -->
                            </li>
                            <!--<li>
                                <a href="#">Pages</a>
                                <ul class="sub-menu">
                                    <li><a href="about.html">About Page</a></li>
                                    <li><a href="gallery.html">Gallery</a></li>
                                    <li><a href="pricing.html">Pricing Plans</a></li>
                                    <li><a href="faq.html">FAQ'S</a></li>
                                </ul> 
                            </li>/.sub-menu -->
                            <li>
                                <a @click="goTo('Courses')" style="font-weight: 700;">Courses</a>
                                <!--<ul class="sub-menu">
                                    <li><a href="courses.html">Courses</a></li>
                                    <li><a href="course-details.html">Course Details</a></li>
                                </ul> /.sub-menu -->
                            </li>
                            <li>
                                <a @click="goTo('Teachers')" style="font-weight: 700;">Teachers</a>
                                <!--<ul class="sub-menu">
                                    <li><a href="teachers.html">Teachers</a></li>
                                    <li><a href="team-details.html">Teachers Details</a></li>
                                    <li><a href="become-teacher.html">Become Teacher</a></li>
                                </ul> /.sub-menu -->
                            </li>
                            <li class="current">
                                <a @click="goTo('News')" style="font-weight: 700;">News</a>
                                <!-- <ul class="sub-menu">
                                    <li><a href="news.html">News Page</a></li>
                                    <li><a href="news-details.html">News Details</a></li>
                                </ul>/.sub-menu -->
                            </li>
                            <li>
                                <a @click="goTo('About')" style="font-weight: 700;">About</a>
                            </li>
                            </ul>
                        </div><!-- /.navbar-collapse -->
                        <div class="right-side-box">
                            <a class="header__search-btn search-popup__toggler" href="#"><i class="kipso-icon-magnifying-glass"></i>
                                <!-- /.kipso-icon-magnifying-glass --></a>
                        </div><!-- /.right-side-box -->
                    </div>
                    <!-- /.container -->
                </nav>
                <div class="site-header__decor">
                    <div class="site-header__decor-row">
                        <div class="site-header__decor-single">
                            <div class="site-header__decor-inner-1"></div><!-- /.site-header__decor-inner -->
                        </div><!-- /.site-header__decor-single -->
                        <div class="site-header__decor-single">
                            <div class="site-header__decor-inner-2"></div><!-- /.site-header__decor-inner -->
                        </div><!-- /.site-header__decor-single -->
                        <div class="site-header__decor-single">
                            <div class="site-header__decor-inner-3"></div><!-- /.site-header__decor-inner -->
                        </div><!-- /.site-header__decor-single -->
                    </div><!-- /.site-header__decor-row -->
                </div><!-- /.site-header__decor -->
            </header><!-- /.site-header -->
            <section class="inner-banner">
                <div class="container">
                    <ul class="list-unstyled thm-breadcrumb">
                        <li><a href="#">Home</a></li>
                        <li class="active"><a href="#">Our news</a></li>
                    </ul><!-- /.list-unstyled -->
                    <h2 class="inner-banner__title">Our news</h2><!-- /.inner-banner__title -->
                </div><!-- /.container -->
            </section><!-- /.inner-banner -->
            <section class="blog-one blog-page">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="blog-one__single">
                                <div class="blog-one__image">
                                    <img :src="require('../../assets/images/blog-2-1.jpeg')" alt="">
                                    <a class="blog-one__plus" href="news-details.html"><i class="kipso-icon-plus-symbol"></i>
                                        <!-- /.kipso-icon-plus-symbol --></a>
                                </div><!-- /.blog-one__image -->
                                <div class="blog-one__content text-center">
                                    <div class="blog-one__meta">
                                        <a data-toggle="tooltip" data-placement="top" title="Posted On Jan 19" href="#"><i class="fa fa-calendar-alt"></i></a>
                                        <a data-toggle="tooltip" data-placement="top" title="No Comments" href="#"><i class="fa fa-comments"></i></a>
                                        <a data-toggle="tooltip" data-placement="top" title="Posted By Admin" href="#"><i class="fa fa-user"></i></a>
                                    </div><!-- /.blog-one__meta -->
                                    <h2 class="blog-one__title"><a href="news-details.html">Summer high school journalism camp</a>
                                    </h2><!-- /.blog-one__title -->
                                    <p class="blog-one__text">Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit
                                        amet finibus eros.</p><!-- /.blog-one__text -->
                                    <a href="news-details.html" class="blog-one__link">Read More</a><!-- /.blog-one__link -->
                                </div><!-- /.blog-one__content -->
                            </div><!-- /.blog-one__single -->
                        </div><!-- /.col-lg-4 -->
                        <div class="col-lg-4">
                            <div class="blog-one__single">
                                <div class="blog-one__image">
                                    <img :src="require('../../assets/images/blog-2-1.jpeg')" alt="">
                                    <a class="blog-one__plus" href="news-details.html"><i class="kipso-icon-plus-symbol"></i>
                                        <!-- /.kipso-icon-plus-symbol --></a>
                                </div><!-- /.blog-one__image -->
                                <div class="blog-one__content text-center">
                                    <div class="blog-one__meta">
                                        <a data-toggle="tooltip" data-placement="top" title="Posted On Jan 19" href="#"><i class="fa fa-calendar-alt"></i></a>
                                        <a data-toggle="tooltip" data-placement="top" title="No Comments" href="#"><i class="fa fa-comments"></i></a>
                                        <a data-toggle="tooltip" data-placement="top" title="Posted By Admin" href="#"><i class="fa fa-user"></i></a>
                                    </div><!-- /.blog-one__meta -->
                                    <h2 class="blog-one__title"><a href="news-details.html">Get a tips to develop a quality
                                            education</a>
                                    </h2><!-- /.blog-one__title -->
                                    <p class="blog-one__text">Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit
                                        amet finibus eros.</p><!-- /.blog-one__text -->
                                    <a href="news-details.html" class="blog-one__link">Read More</a><!-- /.blog-one__link -->
                                </div><!-- /.blog-one__content -->
                            </div><!-- /.blog-one__single -->
                        </div><!-- /.col-lg-4 -->
                        <div class="col-lg-4">
                            <div class="blog-one__single">
                                <div class="blog-one__image">
                                    <img :src="require('../../assets/images/blog-2-1.jpeg')" alt="">
                                    <a class="blog-one__plus" href="news-details.html"><i class="kipso-icon-plus-symbol"></i>
                                        <!-- /.kipso-icon-plus-symbol --></a>
                                </div><!-- /.blog-one__image -->
                                <div class="blog-one__content text-center">
                                    <div class="blog-one__meta">
                                        <a data-toggle="tooltip" data-placement="top" title="Posted On Jan 19" href="#"><i class="fa fa-calendar-alt"></i></a>
                                        <a data-toggle="tooltip" data-placement="top" title="No Comments" href="#"><i class="fa fa-comments"></i></a>
                                        <a data-toggle="tooltip" data-placement="top" title="Posted By Admin" href="#"><i class="fa fa-user"></i></a>
                                    </div><!-- /.blog-one__meta -->
                                    <h2 class="blog-one__title"><a href="news-details.html">Learn variety of programs and
                                            courses</a>
                                    </h2><!-- /.blog-one__title -->
                                    <p class="blog-one__text">Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit
                                        amet finibus eros.</p><!-- /.blog-one__text -->
                                    <a href="news-details.html" class="blog-one__link">Read More</a><!-- /.blog-one__link -->
                                </div><!-- /.blog-one__content -->
                            </div><!-- /.blog-one__single -->
                        </div><!-- /.col-lg-4 -->
                        <div class="col-lg-4">
                            <div class="blog-one__single">
                                <div class="blog-one__image">
                                    <img :src="require('../../assets/images/blog-2-1.jpeg')" alt="">
                                    <a class="blog-one__plus" href="news-details.html"><i class="kipso-icon-plus-symbol"></i>
                                        <!-- /.kipso-icon-plus-symbol --></a>
                                </div><!-- /.blog-one__image -->
                                <div class="blog-one__content text-center">
                                    <div class="blog-one__meta">
                                        <a data-toggle="tooltip" data-placement="top" title="Posted On Jan 19" href="#"><i class="fa fa-calendar-alt"></i></a>
                                        <a data-toggle="tooltip" data-placement="top" title="No Comments" href="#"><i class="fa fa-comments"></i></a>
                                        <a data-toggle="tooltip" data-placement="top" title="Posted By Admin" href="#"><i class="fa fa-user"></i></a>
                                    </div><!-- /.blog-one__meta -->
                                    <h2 class="blog-one__title"><a href="news-details.html">English lectures in french language
                                            courses</a>
                                    </h2><!-- /.blog-one__title -->
                                    <p class="blog-one__text">Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit
                                        amet finibus eros.</p><!-- /.blog-one__text -->
                                    <a href="news-details.html" class="blog-one__link">Read More</a><!-- /.blog-one__link -->
                                </div><!-- /.blog-one__content -->
                            </div><!-- /.blog-one__single -->
                        </div><!-- /.col-lg-4 -->
                        <div class="col-lg-4">
                            <div class="blog-one__single">
                                <div class="blog-one__image">
                                    <img :src="require('../../assets/images/blog-2-1.jpeg')" alt="">
                                    <a class="blog-one__plus" href="news-details.html"><i class="kipso-icon-plus-symbol"></i>
                                        <!-- /.kipso-icon-plus-symbol --></a>
                                </div><!-- /.blog-one__image -->
                                <div class="blog-one__content text-center">
                                    <div class="blog-one__meta">
                                        <a data-toggle="tooltip" data-placement="top" title="Posted On Jan 19" href="#"><i class="fa fa-calendar-alt"></i></a>
                                        <a data-toggle="tooltip" data-placement="top" title="No Comments" href="#"><i class="fa fa-comments"></i></a>
                                        <a data-toggle="tooltip" data-placement="top" title="Posted By Admin" href="#"><i class="fa fa-user"></i></a>
                                    </div><!-- /.blog-one__meta -->
                                    <h2 class="blog-one__title"><a href="news-details.html">Subject that fits your busy life
                                            styles</a>
                                    </h2><!-- /.blog-one__title -->
                                    <p class="blog-one__text">Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit
                                        amet finibus eros.</p><!-- /.blog-one__text -->
                                    <a href="news-details.html" class="blog-one__link">Read More</a><!-- /.blog-one__link -->
                                </div><!-- /.blog-one__content -->
                            </div><!-- /.blog-one__single -->
                        </div><!-- /.col-lg-4 -->
                        <div class="col-lg-4">
                            <div class="blog-one__single">
                                <div class="blog-one__image">
                                    <img :src="require('../../assets/images/blog-2-1.jpeg')" alt="">
                                    <a class="blog-one__plus" href="news-details.html"><i class="kipso-icon-plus-symbol"></i>
                                        <!-- /.kipso-icon-plus-symbol --></a>
                                </div><!-- /.blog-one__image -->
                                <div class="blog-one__content text-center">
                                    <div class="blog-one__meta">
                                        <a data-toggle="tooltip" data-placement="top" title="Posted On Jan 19" href="#"><i class="fa fa-calendar-alt"></i></a>
                                        <a data-toggle="tooltip" data-placement="top" title="No Comments" href="#"><i class="fa fa-comments"></i></a>
                                        <a data-toggle="tooltip" data-placement="top" title="Posted By Admin" href="#"><i class="fa fa-user"></i></a>
                                    </div><!-- /.blog-one__meta -->
                                    <h2 class="blog-one__title"><a href="news-details.html">Are you ready to become a best student
                                        </a>
                                    </h2><!-- /.blog-one__title -->
                                    <p class="blog-one__text">Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit
                                        amet finibus eros.</p><!-- /.blog-one__text -->
                                    <a href="news-details.html" class="blog-one__link">Read More</a><!-- /.blog-one__link -->
                                </div><!-- /.blog-one__content -->
                            </div><!-- /.blog-one__single -->
                        </div><!-- /.col-lg-4 -->
                    </div><!-- /.row -->
                    <div class="post-pagination">
                        <a href="#"><i class="fa fa-angle-double-left"></i><!-- /.fa fa-angle-double-left --></a>
                        <a class="active" href="#">1</a>
                        <a href="#">2</a>
                        <a href="#">3</a>
                        <a href="#">4</a>
                        <a href="#"><i class="fa fa-angle-double-right"></i><!-- /.fa fa-angle-double-left --></a>
                    </div><!-- /.post-pagination -->
                </div><!-- /.container -->
            </section><!-- /.blog-one blog-page -->
            <Footer></Footer>

        </div><!-- /.page-wrapper -->
    </div>
</template>
<script>
import Footer from '../../components/footer/Footer.vue';
import '../../assets/css/style.css'
export default {
    name: 'News',
    components: {
        Footer
    },
    methods :{
      goTo (url) {
         this.link = url;
         switch (url) {
            case 'Courses':
              this.$router.push({ name: 'Courses' });
               break;
            case 'Teachers':
               this.$router.push({ name: 'Teachers' });
               break;
            case 'News':
               this.$router.push({ name: 'News' });
               break;
            case 'About':
               this.$router.push({ name: 'About' });
               break;
            case 'Home':
               this.$router.push({ name: 'Main' });
               break;
         }
      },
    }
}
</script>